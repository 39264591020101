import React from 'react';
import Logo from "./assets/logo512.png"

function Navbar() {
  return (
    <div className="navbar">
            <a href="/">
              <img src={ Logo } alt="Logo" />
            </a>
    </div>
  )
}

export default Navbar